var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(false)?_c('v-col',{attrs:{"cols":"12","lg":"3","md":"6"}},[_c('v-subheader',[_vm._v("Permisos")]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((Object.entries(
                _vm.$store.getters.getPermisos
              ).sort(function (ref, ref$1) {
                            var v1 = ref[1];
                            var v2 = ref$1[1];

                            return (v1 > v2 ? 1 : -1);
})),function(ref){
              var key = ref[0];
              var value = ref[1];
return _c('v-list-item',{key:key,attrs:{"dense":"","value":Number(value)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(value))])],1),(_vm.$root.acceso(key))?_c('v-icon',[_vm._v("mdi-account")]):_vm._e()]}}],null,true)})}),1)],1),_c('v-divider'),_vm._v(" "+_vm._s(_vm.total)+" ")],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" "+_vm._s(_vm.datosFlexible)+" "),_c('v-subheader',[_vm._v("Flex")]),_c('CrearFlexibleGas',{model:{value:(_vm.datosFlexible),callback:function ($$v) {_vm.datosFlexible=$$v},expression:"datosFlexible"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-subheader',[_vm._v("Colors")]),_c('v-card',{staticClass:"pa-3"},_vm._l((_vm.palette),function(color,k){return _c('v-avatar',{key:k,staticClass:"ma-1",attrs:{"color":color,"size":"35","tile":""}},[_vm._v(_vm._s(k))])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }