<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="false" cols="12" lg="3" md="6">
        <v-subheader>Permisos</v-subheader>
        <v-list>
          <v-list-item-group v-model="selected" multiple>
            <v-list-item
              dense
              :value="Number(value)"
              :key="key"
              v-for="[key, value] in Object.entries(
                $store.getters.getPermisos
              ).sort(([, v1], [, v2]) => (v1 > v2 ? 1 : -1))"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ key }}</v-list-item-title>
                  <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-icon v-if="$root.acceso(key)">mdi-account</v-icon>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        {{ total }}
      </v-col>

      <v-col cols="12" md="9">
        {{ datosFlexible }}
        <v-subheader>Flex</v-subheader>
        <CrearFlexibleGas v-model="datosFlexible" />
      </v-col>

      <v-col cols="12" md="3">
        <v-subheader>Colors</v-subheader>
        <v-card class="pa-3">
          <!-- <div class="d-flex justify-space-around" v-for="(subpalette, i) in palette" > -->
          <v-avatar
            v-for="(color, k) in palette"
						:key="k"
            :color="color"
            size="35"
            class="ma-1"
            tile
            >{{ k }}</v-avatar
          >
          <!-- </div> -->
        </v-card>
      </v-col>

      <!-- <v-col cols="12" md="5">
        <v-subheader>Icons</v-subheader>
        <v-card class="pa-3">
          <v-icon v-for="icon in icons" :key="icon.id" class="ma-1"
            >mdi-{{ icon.name }}</v-icon
          >
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import palette from "@/modules/impagados/services/colorPalette.js";

export default {
  components: {
    CrearFlexibleGas: () =>
      import("@/modules/contratos/components/CrearFlexibleGas.vue"),
  },
  async mounted() {
    // console.log(this.$vuetify.icons);
    // this.getIcons();
  },
  data() {
    return {
      datosFlexible: {
        consumoAnual: 2898,
        tarifa: "RL. 1",
      },
      palette,
      selected: [],
      filter: {},

      potencias: {
        feeEnergia: 0,
        consumo: 0,
        tarifa: "2.0TD",
        p1: null,
        p2: null,
        p3: null,
        p4: null,
        p5: null,
        p6: null,
      },
      preciosBOE: {},

      icons: [],
    };
  },
  computed: {
    total() {
      return this.selected.reduce((t, a) => t + a, 0);
    },
  },
  methods: {
    async getIcons() {
      const { data } = await axios(
        "https://materialdesignicons.com/api/package/38EF63D0-4744-11E4-B3CF-842B2B6CFE1B"
      );
      this.icons = data.icons;
    },
  },
};
</script>

<style></style>
